import './App.css';
import React, { useEffect, useState } from 'react';
import fernet from 'fernet';
import { Buffer } from 'buffer/';
import { useNavigate } from 'react-router-dom';
import Loader from './components/Loader';
import Navbar from './components/Navbar';
import PaymentCallback from './pages/PaymentCallback';
import OrderDetails from './pages/OrderDetails';

export const BASE_URL = 'https://api.getfleek.app';
// export const BASE_URL = 'http://127.0.0.1:8000';

const encryptKeyword = (keyword) => {
  try {
    const rawKey = 'PARTNER_PORTAL_ENCRYPTION_KEY2025';
    // console.log("Raw key:", rawKey);
    
    // Pad or truncate the raw key to exactly 32 bytes
    const paddedKey = Buffer.alloc(32);
    Buffer.from(rawKey).copy(paddedKey);
    
    // Convert to url-safe base64
    const b64Key = paddedKey
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');

    // console.log("keyword:", keyword);
    // console.log("Base64 key:", b64Key);

    const secret = new fernet.Secret(b64Key);
    
    // Generate IV using crypto API
    const iv = [];
    const randomBytes = new Uint8Array(16);
    for (let i = 0; i < 16; i++) {
      randomBytes[i] = Math.floor(Math.random() * 256);
      iv.push(randomBytes[i]);
    }

    const token = new fernet.Token({
      secret: secret,
      time: Date.now(),
      iv: iv,
    });

    const fernetToken = token.encode(keyword);
    // console.log(`Generated Fernet token for ${keyword}:`, fernetToken);
    return fernetToken;
  } catch (error) {
    console.error('Error encrypting keyword:', error);
    throw new Error('Failed to encrypt keyword');
  }
};

const GetPortalToken = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      // Direct hostname check (more reliable than localStorage flag)
      const hostname = window.location.hostname;
      const isDomainGetFleek = hostname === 'getfleek.com' || hostname === 'www.getfleek.com';
      
      // Also check localStorage flag as backup
      const redirectedFromDomain = localStorage.getItem('redirectedFromDomain');
      
      // Get keyword from URL path
      const path = window.location.pathname.replace(/\/$/, '');
      let urlKeyword = path.split('/').pop();
      
      // If we're on root path and on getfleek.com domain, use 'fleekusa' as the keyword
      if ((!urlKeyword || urlKeyword === '') && (isDomainGetFleek || redirectedFromDomain === 'getfleek.com')) {
        console.log('Domain-based routing: Using fleekusa as the keyword');
        urlKeyword = 'fleekusa';
        // Clear the flag after using it
        localStorage.removeItem('redirectedFromDomain');
      }

      if (urlKeyword) {
        setIsLoading(true);

        try {
          const generatedToken = encryptKeyword(urlKeyword);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          const raw = JSON.stringify({
            "encrypted_client_id": generatedToken
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
          };

          const response = await fetch(BASE_URL + "/partner/v2/get-portal-token", requestOptions);
          const result = await response.json();
          
          if (result.token) {
            localStorage.setItem('portalToken', result.token);
            setTimeout(() => {
              navigate('/partnermarketplace');
            }, 1000);
          }
        } catch (error) {
          console.error('Error:', error);
          setError(error);
        }
      } else {
        // Improve the error message and add a delay to handle potential race conditions
        const errorMsg = isDomainGetFleek 
          ? "Setting up Fleek USA experience..." 
          : "Please visit the correct URL";
        
        // For getfleek.com domain, we'll set a temporary loading message instead of an error
        if (isDomainGetFleek) {
          // Add a brief delay and retry with fleekusa as keyword
          setTimeout(() => {
            fetchData();
          }, 500);
        } else {
          setError(new Error(errorMsg));
        }
      }
    };

    fetchData();
  }, [navigate]);

  if (error) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100vh',
        color: '#ff6b6b' 
      }}>
        <p>Error: {error.message}</p>
      </div>
    );
  }

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      height: '100vh' 
    }}>
      <Loader />
    </div>
  );
};

function App() {
  return <GetPortalToken />;
}

export default App;
